import React, { Component } from "react";
//import '../../App.css';
import { GoAlertFill } from "react-icons/go";

const Expired = () => {
  return (
    <>
      <div className="App-wrap align-items-center justify-content-center h-100vh bg-theme-gradient">
        <div className="wrap">
          <div className="row g-4 text-center ">
            <div className="col-12 text-center ">
              <svg width="0" height="0">
                <linearGradient id="gradient" x1="0%" y1="100%" x2="0%" y2="0%">
                  <stop stopColor="#3B72D3" offset="0%" />
                  <stop stopColor="#36CBB9" offset="100%" />
                </linearGradient>
              </svg>
              <GoAlertFill className="w-92" style={{ fill: "url(#gradient)" }} />
            </div>
            <div className="col-12">
              <h4 className="text-center my-0">
                Your license have expired. Kindly acquire a valid license to proceed. Contact your system administrator
                for support. Thank you for your cooperation.
              </h4>
            </div>
            <div className="col-12">
              <a href="#" className="btn nbtn-default w-100 text-center p-3 ">
                Request to Renew License
              </a>
            </div>
            <div className="col-12">
              <a href="#" className="btn nbtn-default-outline w-100 text-center p-3 ">
                Reload if your license renewed
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Expired;
