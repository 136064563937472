import React, { useEffect, useState } from "react";
import Header from "./Header";
import { FaArrowLeft } from "react-icons/fa";

export default function Notifications({ changeScreen }) {
  const [notificationsData, setNotificationsData] = useState([]);
  const [summaries, setSummaries] = useState(0);
  const [priority, setPriority] = useState(0);

  const [drafts, setDrafts] = useState(0);

  useEffect(() => {}, []); // The empty dependency array ensures that this effect runs only once on component mount

  const headerChangeScreen = (screen) => {
    changeScreen(screen);
  };

  return (
    <div className="compose-bg height-100vh" style={{ paddingBottom: "10px" }}>
      {/* style={{ height: "100vh" }} */}
      <Header headerChangeScreen={headerChangeScreen} />
      <div>
        <p className="backIcon">
          <FaArrowLeft
            onClick={() => {
              changeScreen("QuickSend");
            }}
            className="arrow-btn"
          />{" "}
          <span>Dashboard</span> | <span>Notifications</span>
          <p className="m-0" style={{ marginTop: "5px" }}>
            SeAMate Dashboard today
          </p>
        </p>
      </div>

      <div className="notification-container">
        <div className="stats-container">
          <span className="left-span">
            <h1 className="txt-main-green">{drafts > 0 && drafts < 10 ? "0" + drafts : drafts}</h1>
            <h4>Draft Ready</h4>
          </span>
          <span className="right-span">
            <h1 className="txt-red">{priority > 0 && priority < 10 ? "0" + priority : priority}</h1>
            <sub>High Priority</sub>
          </span>
        </div>

        <div className="stats-container">
          <span className="left-span">
            <h1 className="prime-color">{summaries > 0 && summaries < 10 ? "0" + summaries : summaries}</h1>
            <h4>Summary Ready</h4>
          </span>
        </div>

        <p>Notifications</p>

        <div className="email-snap">
          <p className="m-0 txt-main-green">Email Snap</p>
          <p className="m-0 font-15">12:05 PM 23th Nov 2023</p>
          <p className="m-0 font-15">30 emails drafts ready</p>
          <p className="m-0 font-15">20 emails summary ready</p>
        </div>
        <div className="email-snap">
          <p className="m-0 txt-main-green">Email Snap</p>
          <p className="m-0 font-15">12:05 PM 23th Nov 2023</p>
          <p className="m-0 font-15">30 emails drafts ready</p>
          <p className="m-0 font-15">20 emails summary ready</p>
        </div>
        <div className="email-snap">
          <p className="m-0 txt-red">Priority emails</p>
          <p className="m-0 font-15">12:05 PM 23th Nov 2023</p>
          <p className="m-0 font-15">30 emails drafts ready</p>
          <p className="m-0 font-15">20 emails summary ready</p>
        </div>

        <div className="email-snap" style={{ color: "grey" }}>
          <p className="m-0">
            <b>Welcome to SeAMate</b>
          </p>
          <p className="m-0 font-15">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        </div>
      </div>
    </div>
  );
}
