//dev-new
const mwURL_Global = "https://dev-portal-apis.nexusocean.ai";
const pluginURL_Global = "https://dev-sam.nexusocean.ai";
const savitar_url = "https://dev-savitar-front-end.nexusocean.ai";
const isProd = 1;
//Prod

// const mwURL_Global = "https://mw.nexusoceanai.com";
// const pluginURL_Global = "https://outlook.nexusoceanai.com"

export { mwURL_Global, pluginURL_Global, savitar_url, isProd };
