import React, { useState, useEffect } from "react";
//import '../../App.css';
import { SiDependabot } from "react-icons/si";
import { setisComposeMail } from "./ComposeNewMail";
// eslint-disable-next-line react/prop-types
export default function ComposeMail({ getComposeNewMail, getImproveNewMail }) {
  const headerChangeScreen = (screen) => {
    changeScreen(screen);
  };
  return (
    <>
      <div className="App-wrap align-items-center justify-content-center h-100vh bg-theme-gradient">
        <div className="wrap p-4">
          <div className="row g-2 text-center ">
            <div className="col-12 text-center ">
              <svg width="0" height="0">
                <linearGradient id="gradient" x1="0%" y1="100%" x2="0%" y2="0%">
                  <stop stopColor="#3B72D3" offset="0%" />
                  <stop stopColor="#36CBB9" offset="100%" />
                </linearGradient>
              </svg>
              {/* <SiDependabot className="w-92" style={{ fill: "url(#gradient)" }} /> */}
              <svg xmlns="http://www.w3.org/2000/svg" className="w-92" viewBox="0 0 84 87.144">
                <defs>
                  <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#36cbb9" />
                    <stop offset="1" stopColor="#3b72d3" />
                  </linearGradient>
                  <clipPath id="b">
                    <rect width="84" height="87.144" fill="url(#a)" />
                  </clipPath>
                </defs>
                <g clipPath="url(#b)">
                  <path
                    d="M89.336,336.584H20.63c-.179-.063-.355-.141-.539-.186a8.924,8.924,0,0,1-4.443-2.475c.153-.17.242-.278.341-.377C26.808,322.722,38,311.5,48.84,300.7a19.872,19.872,0,0,0,12.281-.02c10.663,10.658,21.957,21.96,32.616,32.623.195.2.377.4.613.658-.532.581-.581.6-1.022,1.049a5.474,5.474,0,0,1-3.992,1.578"
                    transform="translate(-12.981 -249.439)"
                    fill="url(#a)"
                  />
                  <path
                    d="M379.469,265.678a6.386,6.386,0,0,1-.927,2.881,6.021,6.021,0,0,1-.581,1.01l-21.8-21.806a5.1,5.1,0,0,0,.688-.506q10.919-10.906,21.83-21.819a6.522,6.522,0,0,1,.79-.586Z"
                    transform="translate(-295.469 -186.536)"
                    fill="url(#a)"
                  />
                  <path
                    d="M93.722,109.01c-.25-.236-.477-.44-.693-.656-2.967-2.965-5.97-5.972-8.938-8.937a18.686,18.686,0,0,1-15.749.006q-4.758,4.742-9.51,9.5a1.452,1.452,0,0,1-.2.128c-.186-.171-.392-.347-.583-.538Q49.932,100.4,41.834,92.263a2.068,2.068,0,0,1-.553-1.311q-.05-11.31-.019-22.621c.012-4.9,2.922-8.47,7.59-9.334a11.267,11.267,0,0,1,2.032-.112c16.919-.006,33.838.048,50.757-.035a9.188,9.188,0,0,1,9.333,6.979,11.426,11.426,0,0,1,.252,2.355q.028,11.311-.005,22.621a1.735,1.735,0,0,1-.387,1.124q-8.355,8.42-16.759,16.791c-.1.1-.21.173-.352.289M76.257,60.655A20.189,20.189,0,1,0,96.4,80.921a20.2,20.2,0,0,0-20.14-20.266"
                    transform="translate(-34.222 -48.821)"
                    fill="url(#a)"
                  />
                  <path
                    d="M1.468,270.6A8.819,8.819,0,0,1,0,265.55q0-19.383,0-38.767v-.678l22.97,22.988L1.468,270.6"
                    transform="translate(0 -187.576)"
                    fill="url(#a)"
                  />
                  <path
                    d="M200.246,8.341H176.285a6.459,6.459,0,0,1,.423-.534c1.659-1.665,3.309-3.339,4.987-4.985a8.962,8.962,0,0,1,12.993-.069c1.847,1.789,3.629,3.645,5.557,5.588"
                    transform="translate(-146.245 0.001)"
                    fill="url(#a)"
                  />
                  <path
                    d="M9.188,198.236l3.78-3.811v7.628l-3.78-3.817"
                    transform="translate(-7.622 -161.294)"
                    fill="url(#a)"
                  />
                  <path
                    d="M462.289,194.057l3.761,3.773-3.761,3.745Z"
                    transform="translate(-383.512 -160.989)"
                    fill="url(#a)"
                  />
                  <path
                    d="M158.594,80.823a18.131,18.131,0,1,1-18.166,18.034,18.051,18.051,0,0,1,18.166-18.034m-2.972,25.848L166.8,95.476l-4.76-4.789-11.2,11.194,4.779,4.79m12.87-12.458c.73-.769,1.5-1.548,2.231-2.361a1.722,1.722,0,0,0,.027-2.5c-.831-.911-1.706-1.787-2.62-2.614a1.669,1.669,0,0,0-2.415-.019c-.845.745-1.646,1.541-2.449,2.3l5.226,5.194m-14.235,14-4.952-4.962c-.587,1.749-1.223,3.55-1.779,5.376a1.4,1.4,0,0,0,.185,1.193,1.437,1.437,0,0,0,1.2.149c1.794-.543,3.565-1.162,5.348-1.756"
                    transform="translate(-116.498 -67.05)"
                    fill="url(#a)"
                  />
                </g>
              </svg>
            </div>

            <div className="col-12">
              <button onClick={getComposeNewMail} id="ComposeNewMail" className="btn nbtn-default w-100 text-center">
                Compose New Email
              </button>
            </div>

            <div className="col-12">
              <button onClick={getImproveNewMail} id="ComposeNewMail" className="btn nbtn-default w-100 text-center">
                Improve Existing Email
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
