import React, { useRef, useState, useEffect, useCallback } from "react";
import Header from "./Header";
import { FaArrowLeft } from "react-icons/fa";
import { IoCopyOutline } from "react-icons/io5";
import { PiMicrosoftOutlookLogoFill } from "react-icons/pi";
import { MdTranslate, MdOutlineTypeSpecimen, MdEditNote } from "react-icons/md";
import { GoHistory } from "react-icons/go";
import { ImMagicWand } from "react-icons/im";
import { FiEdit } from "react-icons/fi";
//import '../../App.css';
import { SiDependabot } from "react-icons/si";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import ComposeMail from "./ComposeMail";
import ComposeNewMailImproveEmail from "./ComposeNewMailImproveEmail";

import Loading from "./loading";
import { Parser } from "html-to-react";

import { mwURL_Global } from "../../global";
import Loading_summary from "./loading_summary";
import Loading_Compose from "./Loading_Compose";

// eslint-disable-next-line react/prop-types
export default function ComposeNewMail({ changeScreen }) {
  const [isInfoFetched, setIsInfoFetched] = useState(false);
  const [newEmailBody, setnewEmailBody] = useState("");
  const [newEmailSubject, setnewEmailSubject] = useState("");
  const [validationmessage, setvalidationmessage] = useState("");
  const [isEmailBody, setisEmailBody] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isComposeMail, setisComposeMail] = useState(true);
  const [isImproveMail, setisImproveMail] = useState(false);
  const [disableButtons, setdisableButtons] = useState(false);

  // new code for custome dropdown
  const [langValue, setLangValue] = useState("English");
  const [langCheck, setLangCheck] = useState(false);
  const [formatValue, setFormatValue] = useState("Formal");
  const [formatCheck, setFormatCheck] = useState(false);
  const [sizeValue, setSizeValue] = useState("Medium");
  const [sizeCheck, setSizeCheck] = useState(false);
  const [hPValue, setHPValue] = useState("");
  const [hPCheck, setHPCheck] = useState(false);
  const languageClick = (lang) => {
    // Change label value when clicked
    setLabelValue(lang);
  };
  const setDrop = () => {
    setLangCheck(false);
    setFormatCheck(false);
    setSizeCheck(false);
    setHPCheck(false);
  };

  const emailname = useRef(null);
  const emailprompt = useRef(null);
  const htmlParser = new Parser();
  const emailprompttext = "";
  const aiserviceURL = localStorage.getItem("aiserviceURL");
  useEffect(() => {}, []);

  const getnewEmailBody = () => {
    if (emailprompt.current.value.trim() == "") {
      setvalidationmessage("Enter email prompt");
    } else if (emailprompt.current.value.trim().length < 10) {
      setvalidationmessage("Prompt length should be greater than 10 characters");
    } else {
      setvalidationmessage("");
      setisLoading(true);
      setdisableButtons(true);

      localStorage.setItem("emailname", emailname.current.value);
      localStorage.setItem("emailprompt", emailprompt.current.value);
      axios
        .post(
          aiserviceURL + "/compose",
          {
            user_email: Office.context.mailbox.userProfile.emailAddress,
            from_email: Office.context.mailbox.userProfile.emailAddress,
            to_email_name: emailname.current.value ?? "[Name]",
            user_prompt: emailprompt.current.value,
            persona: sessionStorage.getItem("primarypersona") ?? "HSEQ / Marine",
            language: localStorage.getItem("composeLanguage") ?? "en-US",
            tone: formatValue,
            stream: false,
            content_length: sizeValue,
          },
          { "Content-Type": "application/json" }
        )
        .then((response) => {
          setisLoading(false);
          setnewEmailBody(response.data.email_body);
          setnewEmailSubject(response.data.email_subject);
          setisEmailBody(true);
          setdisableButtons(false);
        })
        .catch((error) => {
          // Handle any errors
          setisLoading(false);
          setdisableButtons(false);
          console.error("Error:", error);
        });
    }
  };

  const getnewEmailBodyRegenerate = () => {
    setdisableButtons(true);
    setvalidationmessage("");
    setisLoading(true);

    axios
      .post(
        aiserviceURL + "/compose",
        {
          user_email: Office.context.mailbox.userProfile.emailAddress,
          from_email: Office.context.mailbox.userProfile.emailAddress,
          to_email_name: localStorage.getItem("emailname") ?? "[Name]",
          user_prompt: localStorage.getItem("emailprompt"),
          persona: sessionStorage.getItem("primarypersona") ?? "HSEQ / Marine",
          language: localStorage.getItem("composeLanguage") ?? "en-US",
          tone: formatValue,
          stream: false,
          content_length: sizeValue,
        },
        { "Content-Type": "application/json" }
      )
      .then((response) => {
        setisLoading(false);
        setnewEmailBody(response.data.email_body);
        setnewEmailSubject(response.data.email_subject);
        setisEmailBody(true);
        setdisableButtons(false);
      })
      .catch((error) => {
        // Handle any errors
        setisLoading(false);
        console.error("Error:", error);
        setdisableButtons(false);
      });
  };

  const headerChangeScreen = (screen) => {
    changeScreen(screen);
  };

  const NewEmail = () => {
    localStorage.setItem("emailprompt", "");
    localStorage.setItem("emailname", "");
    setvalidationmessage("");
    setisLoading(false);
    setisEmailBody(false);
  };

  const copytoOutlook = () => {
    setSubject();
    setItemBody();
  };

  function setSubject() {
    // Customize the subject with today's date.

    const subject = newEmailSubject;

    Office.context.mailbox.item.subject.setAsync(subject, { asyncContext: {} }, (asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        console.log(asyncResult.error.message);
        return;
      }
    });
  }

  // Inserts data at the current cursor position.
  function setItemBody() {
    let item = Office.context.mailbox.item;
    item.body.getTypeAsync((asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        console.log(asyncResult.error.message);
        return;
      }
      if (asyncResult.value === Office.CoercionType.Html) {
        item.body.setAsync(
          "<html><body><div>" + newEmailBody.replaceAll("\n", "<br/>") + "</div></body></html>",

          { coercionType: Office.CoercionType.Html, asyncContext: { optionalVariable1: 1, optionalVariable2: 2 } },
          (asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
              console.log(asyncResult.error.message);
              return;
            }
          }
        );
      } else {
        item.body.setAsync(
          "<pre style='font-family:inherit; font-size:inherit'>" + newEmailBody + "</pre>",
          { coercionType: Office.CoercionType.Text, asyncContext: { optionalVariable1: 1, optionalVariable2: 2 } },
          (asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
              console.log(asyncResult.error.message);
              return;
            }
          }
        );
      }
    });
  }

  const getComposeNewMail = () => {
    console.log("Change Screen called");
    localStorage.setItem("emailprompt", "");
    localStorage.setItem("emailname", "");
    setvalidationmessage("");
    setisComposeMail(false);
  };

  const getImproveNewMail = () => {
    console.log("Improve Email called");
    localStorage.setItem("emailname", "");
    localStorage.setItem("emailaction", "");
    setvalidationmessage("");
    setisImproveMail(true);
    //setisComposeMail(true);
  };

  const backFromImproveNewMail = () => {
    localStorage.setItem("emailname", "");
    localStorage.setItem("emailaction", "");
    setvalidationmessage("");
    setisImproveMail(false);
  };

  const itemChanged = useCallback((e) => {
    console.log("itemChanged called");
    if (Office.context.mailbox.item.displayReplyForm != undefined) {
      setCurrentScreen("QuickSend");
    } else {
      //setCurrentScreen("ComposeMail");
      setisImproveMail(false);
      setisComposeMail(true);
    }
  }, []);

  const UpdateTaskPaneUI = async (item) => {
    // Assuming that item is always a read item (instead of a compose item).
    if (item != null) {
      console.log(item.subject);
      setisImproveMail(false);
      setisComposeMail(true);
      //getEmailBody();
    }
  };
  Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, itemChanged);

  const handleComposeLanguageDropdownValueChange = (e) => {
    localStorage.setItem("composeLanguage", e.target.value);
  };

  const setLanguage = (lang) => {
    setLangValue(lang);
    localStorage.setItem("composeLanguage", lang);
  };

  const getLanguageName = (languageCode) => {
    switch (languageCode) {
      case "en-US":
        return "English";
      case "ja-JP":
        return "Japanese";
      case "da-DK":
        return "Danish";
      case "yue-CN":
        return "Cantonese";
      case "zh-CN":
        return "Chinese";
      case "nb-NO":
        return "Norwegian";
      case "ms-MY":
        return "Bahasa Malaysia";
      case "id-ID":
        return "Bahasa Indonesia";
      case "th-TH":
        return "Thai";
      case "vi-VN":
        return "Vietnamese";
      case "fil-PH":
        return "Tagalog";
      case "ko-KR":
        return "Korean";
      case "ar-AE":
        return "Arabic";
      case "el-GR":
        return "Greek";
      case "de-DE":
        return "German";
      case "es-ES":
        return "Spanish";
      case "fr-FR":
        return "French";
      case "it-IT":
        return "Italian";
      default:
        return "English";
    }
  };
  return (
    <>
      <div className="quicksend-container hfex">
        <div className=""></div>

        {isImproveMail ? (
          <>
            <ComposeNewMailImproveEmail backFromImproveNewMail={backFromImproveNewMail}></ComposeNewMailImproveEmail>
          </>
        ) : (
          <>
            {isComposeMail ? (
              <ComposeMail getComposeNewMail={getComposeNewMail} getImproveNewMail={getImproveNewMail}></ComposeMail>
            ) : (
              <div className="compose-bg min-height-100vh position-relative ">
                {isLoading ? (
                  <div className="min-vh-100  d-flex justify-content-center align-items-center">
                    <div className="spinner-container d-flex justify-content-center align-items-center">
                      <Spinner animation="border" role="status" className="p-10">
                        <Loading_Compose />
                      </Spinner>
                    </div>
                  </div>
                ) : (
                  <>
                    {!isEmailBody ? (
                      <>
                        <div className="back-wrap p-0 m-0 row align-items-center " style={{ marginRight: "3px" }}>
                          <div className="col-8">
                            <p className="p-2 pt-0">
                              <FaArrowLeft
                                className="arrow-btn mx-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setisComposeMail(true);
                                }}
                              />{" "}
                              <span>Compose Mail</span>
                            </p>
                          </div>
                          <div className="col-4">
                            {/* <select
                                    className="dropdown"
                                    id="composeLanguageDropdown"
                                    name="composeLanguageDropdown"
                                    onChange={handleComposeLanguageDropdownValueChange}
                                    defaultValue={localStorage.getItem("composeLanguage") ?? "en-US"}
                                    disabled={disableButtons}
                                  >
                                    <option value="en-US">English</option>
                                    <option value="ja-JP">Japanese</option>
                                    <option value="da-DK">Danish</option>
                                    <option value="yue-CN">Cantonese</option>
                                    <option value="zh-CN">Chinese</option>
                                    <option value="nb-NO">Norwegian</option>
                                    <option value="ms-MY">Bahasa Malaysia</option>
                                    <option value="id-ID">Bahasa Indonesia</option>
                                    <option value="th-TH">Thai</option>
                                    <option value="vi-VN">Vietnamese</option>
                                    <option value="fil-PH">Tagalog</option>
                                    <option value="ko-KR">Korean</option>
                                    <option value="ar-AE">Arabic</option>
                                    <option value="el-GR">Greek</option>
                                    <option value="de-DE">German</option>
                                    <option value="es-ES">Spanish</option>
                                    <option value="fr-FR">French</option>
                                    <option value="it-IT">Italian</option>
                                  </select> */}
                          </div>
                        </div>

                        <div className="compose-wrap container ">
                          <div className="v-row g-4 text-center ">
                            <div className="v-col-auto">
                              <input
                                type="text"
                                ref={emailname}
                                className="form-control"
                                placeholder="Enter Recipient name"
                                defaultValue={localStorage.getItem("emailname")}
                              />
                            </div>
                            <div className="v-col-auto ">
                              <div className="card ">
                                <textarea
                                  ref={emailprompt}
                                  className="h-100 compose-email"
                                  placeholder="Enter prompt for Generating New email"
                                  defaultValue={localStorage.getItem("emailprompt")}
                                ></textarea>
                              </div>
                              <div className="pt-1 quickrepyvalidationtext">{validationmessage}</div>
                            </div>
                            <div className="v-col-auto">
                              <div className="row g-1 ">
                                <div className="col-4">
                                  <div className="dropdown-cust">
                                    <button
                                      className="dropbtn"
                                      onClick={() => {
                                        setDrop();
                                        setLangCheck(!langCheck);
                                      }}
                                      disabled={disableButtons}
                                    >
                                      <MdTranslate width={20} className="svg-fill me-1 icon-20" />{" "}
                                      <span>{getLanguageName(localStorage.getItem("composeLanguage"))}</span>
                                    </button>
                                    <input type="checkbox" checked={langCheck} />
                                    <div className="dropdown-content">
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("en-US");
                                          setLangCheck(false);
                                        }}
                                      >
                                        English
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("ja-JP");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Japanese
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("da-DK");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Danish
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("yue-CN");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Cantonese
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("zh-CN");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Chinese
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("nb-NO");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Norwegian
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("ms-MY");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Bahasa Malaysia
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("id-ID");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Bahasa Indonesia
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("th-TH");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Thai
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("vi-VN");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Vietnamese
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("fil-PH");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Tagalog
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("ko-KR");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Korean
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("ar-AE");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Arabic
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("el-GR");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Greek
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("de-DE");
                                          setLangCheck(false);
                                        }}
                                      >
                                        German
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("es-ES");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Spanish
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("fr-FR");
                                          setLangCheck(false);
                                        }}
                                      >
                                        French
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("it-IT");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Italian
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="dropdown-cust">
                                    <button
                                      className="dropbtn"
                                      onClick={() => {
                                        setDrop();
                                        setFormatCheck(!formatCheck);
                                      }}
                                    >
                                      <MdOutlineTypeSpecimen width={20} className="svg-fill me-1 icon-20" />{" "}
                                      <span>{formatValue}</span>
                                    </button>
                                    <input type="checkbox" checked={formatCheck} />
                                    <div className="dropdown-content">
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Formal");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Formal
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Professional");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Professional
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Casual");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Casual
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Prof. Friendly ");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Prof. Friendly{" "}
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Urgent");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Urgent
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Appreciative");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Appreciative
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Funny");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Funny
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="dropdown-cust">
                                    <button
                                      className="dropbtn "
                                      onClick={() => {
                                        setDrop();
                                        setSizeCheck(!sizeCheck);
                                      }}
                                    >
                                      <MdEditNote className="svg-fill mx-2 icon-20" /> <span>{sizeValue}</span>
                                    </button>
                                    <input type="checkbox" checked={sizeCheck} />
                                    <div className="dropdown-content right-side">
                                      {/* <a href="#" onClick={() => {setSizeValue("Extra Short"); setSizeCheck(false)}}>Extra Short</a> */}
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setSizeValue("Short");
                                          setSizeCheck(false);
                                        }}
                                      >
                                        Short
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setSizeValue("Medium");
                                          setSizeCheck(false);
                                        }}
                                      >
                                        Medium
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setSizeValue("Long");
                                          setSizeCheck(false);
                                        }}
                                      >
                                        Long
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="footer-btn-wrap">
                          <div className="container">
                            <div className="row g-1">
                              <div className="col">
                                <button
                                  className="btn nbtn-default w-100 text-center"
                                  disabled={disableButtons}
                                  onClick={getnewEmailBody}
                                >
                                  Write Email{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="back-wrap back-wrap-paddingl4">
                          <p className="p-2 pt-0">
                            <FaArrowLeft
                              className="arrow-btn me-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                //setisComposeMail(true);
                                setisEmailBody(false);
                              }}
                            />{" "}
                            {/* <span>Back</span> */}
                          </p>
                        </div>
                        <div className="compose-wrap container ">
                          <div className="v-row g-4 text-center ">
                            <div className="v-col-auto">
                              <>
                                <b>{newEmailSubject}</b>
                              </>
                            </div>
                            <div className="v-col-auto ">
                              <div className="card ">
                                <pre className="p-2 my-0">{htmlParser.parse(newEmailBody)}</pre>
                              </div>
                              <div className="pt-1 ">{validationmessage}</div>
                            </div>
                            <div className="v-col-auto">
                              {/* <button className="dropbtn px-1 text-center" ><ImMagicWand className="svg-fill me-1 icon-20"/><span className="text-theme">Select text to Improve</span></button> */}
                              <div className="row g-1 ">
                                <div className="col-4">
                                  <div className="dropdown-cust">
                                    <button
                                      className="dropbtn"
                                      onClick={() => {
                                        setDrop();
                                        setLangCheck(!langCheck);
                                      }}
                                      disabled={disableButtons}
                                    >
                                      <MdTranslate width={20} className="svg-fill me-1 icon-20" />{" "}
                                      <span>{getLanguageName(localStorage.getItem("composeLanguage"))}</span>
                                    </button>
                                    <input type="checkbox" checked={langCheck} />
                                    <div className="dropdown-content">
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("en-US");
                                          setLangCheck(false);
                                        }}
                                      >
                                        English
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("ja-JP");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Japanese
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("da-DK");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Danish
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("yue-CN");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Cantonese
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("zh-CN");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Chinese
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("nb-NO");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Norwegian
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("ms-MY");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Bahasa Malaysia
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("id-ID");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Bahasa Indonesia
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("th-TH");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Thai
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("vi-VN");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Vietnamese
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("fil-PH");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Tagalog
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("ko-KR");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Korean
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("ar-AE");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Arabic
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("el-GR");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Greek
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("de-DE");
                                          setLangCheck(false);
                                        }}
                                      >
                                        German
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("es-ES");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Spanish
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("fr-FR");
                                          setLangCheck(false);
                                        }}
                                      >
                                        French
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setLanguage("it-IT");
                                          setLangCheck(false);
                                        }}
                                      >
                                        Italian
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="dropdown-cust">
                                    <button
                                      className="dropbtn"
                                      onClick={() => {
                                        setDrop();
                                        setFormatCheck(!formatCheck);
                                      }}
                                    >
                                      <MdOutlineTypeSpecimen width={20} className="svg-fill me-1 icon-20" />{" "}
                                      <span>{formatValue}</span>
                                    </button>
                                    <input type="checkbox" checked={formatCheck} />
                                    <div className="dropdown-content">
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Formal");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Formal
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Professional");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Professional
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Casual");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Casual
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Prof. Friendly ");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Prof. Friendly{" "}
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Urgent");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Urgent
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Appreciative");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Appreciative
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setFormatValue("Funny");
                                          setFormatCheck(false);
                                        }}
                                      >
                                        Funny
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="dropdown-cust">
                                    <button
                                      className="dropbtn "
                                      onClick={() => {
                                        setDrop();
                                        setSizeCheck(!sizeCheck);
                                      }}
                                    >
                                      <MdEditNote className="svg-fill mx-2 icon-20" /> <span>{sizeValue}</span>
                                    </button>
                                    <input type="checkbox" checked={sizeCheck} />
                                    <div className="dropdown-content right-side">
                                      {/* <a href="#" onClick={() => {setSizeValue("Extra Short"); setSizeCheck(false)}}>Extra Short</a> */}
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setSizeValue("Short");
                                          setSizeCheck(false);
                                        }}
                                      >
                                        Short
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setSizeValue("Medium");
                                          setSizeCheck(false);
                                        }}
                                      >
                                        Medium
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setSizeValue("Long");
                                          setSizeCheck(false);
                                        }}
                                      >
                                        Long
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="footer-btn-wrap">
                          <div className="container">
                            <div className="row g-1">
                              <div className="col-auto" title="Edit Email">
                                <button className="btn nbtn-default w-100 text-center" onClick={NewEmail}>
                                  <FiEdit stroke="#fff" style={{ width: "20px", height: "20px" }} />
                                </button>
                              </div>
                              <div className="col">
                                <button
                                  className="btn nbtn-default w-100 text-center"
                                  onClick={getnewEmailBodyRegenerate}
                                  disabled={disableButtons}
                                >
                                  {" "}
                                  Regenerate{" "}
                                </button>
                              </div>

                              <div className="col-auto" title="Copy Email">
                                <button
                                  className="btn nbtn-default w-100 text-center"
                                  onClick={copytoOutlook}
                                  disabled={disableButtons}
                                  title="Copy Email"
                                >
                                  {" "}
                                  <PiMicrosoftOutlookLogoFill
                                    stroke="#fff"
                                    style={{ width: "20px", height: "20px" }}
                                  />{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
