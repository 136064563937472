import axios from "axios";

import { mwURL_Global } from "./global";

export async function checkRestrictedSender(useremail, senderemail) {
  try {
    const response = await axios.get(mwURL_Global + "/v1/CheckFilterEmail", {
      params: {
        useremail: useremail,
        senderemail: senderemail,
      },
    });

    if (response.data.code === 200) {
      if (response.data.result.sensitivesender === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    // Handle any errors
    localStorage.setItem("ErrorMessage", "Something Went Wrong");
    setCurrentScreen("Somethingwentwrong");
    console.error("Error:", error);
    throw error; // Re-throw error to propagate it further if needed
  }
}
