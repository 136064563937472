import MainLogo from "../../../assets/test-img.png";
import React, { useState, useEffect } from "react";
import subLogo from "../../../assets/nexusoceanlogo.png";
import gdprLogo from "../../../assets/gdpr.png";

import { mwURL_Global, pluginURL_Global } from "../../global";

export default function GetStarted({ changeScreen }) {
  const [checkboxAlert, setcheckboxAlert] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [licenseData, setlicenseData] = useState({});
  const [isInfoFetched, setIsInfoFetched] = useState(false);

  //------Checkbox activate-------//
  const [isAccepted, setAccepted] = useState(false);
  //----------//

  const termsConditions = () => {
    let dialog;
    Office.context.ui.displayDialogAsync(
      pluginURL_Global + "/link/Terms_and_Conditions.html",
      { height: 80, width: 50 },
      function (asyncResult) {
        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      }
    );
    function processMessage(arg) {
      const messageFromDialog = JSON.parse(arg.message);
      showUserName(messageFromDialog.name);
    }
  };

  const privacyPolicy = () => {
    let dialog;
    Office.context.ui.displayDialogAsync(
      pluginURL_Global + "/link/Privacy_Policy.html",
      { height: 80, width: 50 },
      function (asyncResult) {
        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      }
    );
    function processMessage(arg) {
      const messageFromDialog = JSON.parse(arg.message);
      showUserName(messageFromDialog.name);
    }
  };

  const gdprConsent = () => {
    let dialog;
    Office.context.ui.displayDialogAsync(
      pluginURL_Global + "/link/Gdpr_Consent.html",
      { height: 80, width: 50 },
      function (asyncResult) {
        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      }
    );
    function processMessage(arg) {
      const messageFromDialog = JSON.parse(arg.message);
      showUserName(messageFromDialog.name);
    }
  };

  const getStartedButton = () => {
    var termscheckbox = document.getElementById("termscheckbox");
    if (termscheckbox.checked) {
      localStorage.setItem("isFirstUse", "false");
      if (Office.context.mailbox.item.displayReplyForm != undefined) {
        // read mode
        changeScreen("QuickSend");
      } else {
        // compose mode
        changeScreen("ComposeNewMail");
        //setCurrentScreen("ComposeMail");
      }
      //changeScreen("QuickSend");
    } else {
      setcheckboxAlert("Please agree terms and conditions before going ahead");
    }
  };

  const headerChangeScreen = (screen) => {
    changeScreen(screen);
  };

  return (
    <div className="h-100vh bg-theme-gradient d-flex align-items-center ">
      <div className="center">
        <p className="mb-0">
          <b>Welcome to</b>
        </p>
        <h1 className="txt-main-green font-20 pt-0 pb-2">SeAMate</h1>

        <img src={MainLogo} alt="" width={200} />

        <div className="checkbox-container">
          <div className="container">
            <div className="row g-1">
              <div className="col-auto">
                <input
                  type="checkbox"
                  id="termscheckbox"
                  className="check"
                  onChange={() => {
                    setAccepted(!isAccepted);
                  }}
                />
              </div>
              <div className="col">
                <label>
                  Hereby, I Accept the{" "}
                  <span className="txt-main-green cursor-pointer" onClick={gdprConsent}>
                    GDPR Consent
                  </span>{" "}
                  <span className="txt-main-green cursor-pointer" onClick={privacyPolicy}>
                    Privacy Policy
                  </span>{" "}
                  and{" "}
                  <span className="txt-main-green cursor-pointer" onClick={termsConditions}>
                    Terms & Conditions
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4">
          <label>{checkboxAlert}</label>
        </div>
        <div className="pb-2">
          <button
            onClick={getStartedButton}
            id="GetStarted"
            className="bg-main-green started-btn cursor-pointer mb-4"
            disabled={!isAccepted ? true : false}
          >
            Lets Get Started
          </button>
        </div>
        <p className="navigate-para txt-main-green py-2">
          <b>Your maritime email assistant for concise summarise, expert drafting, and peak productivity.</b>
        </p>
        <div className="py-2">
          <img src={subLogo} alt="" className="sub-logo" />
        </div>
        <img src={gdprLogo} alt="" className="gdpr-logo" />
      </div>
    </div>
  );
}
