import React, { useEffect, useState } from "react";
import GetStarted from "./GetStarted";
import Profile from "./Profile";
import Notifications from "./Notifications";
import QuickSend from "./QuickSend";
import axios from "axios";
import Expired from "./expired";
import Somethingwentwrong from "./Somethingwentwrong";
import FirstScreen from "./FirstScreen";
import ComposeMail from "./ComposeMail";
import ComposeNewMail from "./ComposeNewMail";
import { mwURL_Global, isProd } from "../../global";
import KeyPeoples from "./KeyPeoples";
export default function App() {
  const [currentScreen, setCurrentScreen] = useState("FirstScreen");

  const [isInfoFetched, setIsInfoFetched] = useState(false);
  const [licenseData, setlicenseData] = useState([]);

  useEffect(() => {
    if (Office.context.mailbox.item.displayReplyForm != undefined) {
      // read mode

      console.log("firstuse:", localStorage.getItem("isFirstUse"));
      // Use the useEffect hook for asynchronous operations
      localStorage.setItem("ErrorMessage", "");

      if (sessionStorage.getItem("LoginDetails") && localStorage.getItem("isFirstUse") === "false") {
        changeScreen("QuickSend");
      } else {
        axios
          .get(mwURL_Global + "/license/v2/license/check", {
            params: {
              email: Office.context.mailbox.userProfile.emailAddress.toLowerCase(),
            },
          })
          .then((response) => {
            ///console.log("Response:", response);
            // Handle the response
            setIsInfoFetched(true);
            if (response.data.code === 200) {
              if (response.data.result.result.domainExists == true && response.data.result.result.license == true) {
                sessionStorage.setItem("LoginDetails", JSON.stringify(response.data.result.result));

                var primarypersona = response.data.result.result.typeOfPersonaLicense.map(function (val) {
                  if (val.isPrimary === true) {
                    sessionStorage.setItem("primarypersona", val.name);
                    return;
                  } else {
                    return "";
                  }
                });

                if (isProd == 0) {
                  console.log("Loading Local ai service");
                  localStorage.setItem("aiserviceURL", "http://127.0.0.1:8000");
                } else {
                  localStorage.setItem("aiserviceURL", response.data.result.result.aiserviceURL);
                }

                console.log("response.data.result.result.aiserviceURL", response.data.result.result.aiserviceURL);
                if (response.data.result.result.aiserviceURL === "") {
                  console.log("1", response.data.result.result.aiserviceURL);
                  localStorage.setItem("ErrorMessage", "AI Service URL is not set");
                  setCurrentScreen("Somethingwentwrong");
                } else if (response.data.result.result.aiserviceURL === undefined) {
                  console.log("2", response.data.result.result.aiserviceURL);
                  localStorage.setItem("ErrorMessage", "AI Service URL is not set");
                  setCurrentScreen("Somethingwentwrong");
                } else if (response.data.result.result.aiserviceURL === null) {
                  console.log("3", response.data.result.result.aiserviceURL);
                  localStorage.setItem("ErrorMessage", "AI Service URL is not set");
                  setCurrentScreen("Somethingwentwrong");
                } else {
                  if (localStorage.getItem("isFirstUse") === "false") {
                    changeScreen("QuickSend");
                  } else if (localStorage.getItem("isFirstUse") === null) {
                    changeScreen("GetStarted");
                  } else {
                    setCurrentScreen("GetStarted");
                  }
                }
              } else {
                changeScreen("Expired");
              }
            } else {
              //if user not found
              //console.log("response.data.message:", response.data.message);
              localStorage.setItem("ErrorMessage", response.data.message);
              setCurrentScreen("Somethingwentwrong");
            }

            console.log("Response:", response.data);
            // Process the response data here
          })
          .catch((error) => {
            // Handle any errors
            localStorage.setItem("ErrorMessage", "Something Went Wrong");
            setCurrentScreen("Somethingwentwrong");
            console.error("Error:", error);
          });
      }
    } else {
      // compose mode

      axios
        .get(mwURL_Global + "/license/v2/license/check", {
          params: {
            email: Office.context.mailbox.userProfile.emailAddress.toLowerCase(),
          },
        })
        .then((response) => {
          setIsInfoFetched(true);
          if (response.data.code === 200) {
            if (response.data.result.result.domainExists == true && response.data.result.result.license == true) {
              sessionStorage.setItem("LoginDetails", JSON.stringify(response.data.result.result));
              var primarypersona = response.data.result.result.typeOfPersonaLicense.map(function (val) {
                if (val.isPrimary === true) {
                  sessionStorage.setItem("primarypersona", val.name);
                  return;
                } else {
                  return "";
                }
              });

              if (isProd == 0) {
                console.log("Loading Local ai service");
                localStorage.setItem("aiserviceURL", "http://127.0.0.1:8000");
              } else {
                localStorage.setItem("aiserviceURL", response.data.result.result.aiserviceURL);
              }

              if (response.data.result.result.aiserviceURL === "") {
                console.log("1", response.data.result.result.aiserviceURL);
                localStorage.setItem("ErrorMessage", "AI Service URL is not set");
                setCurrentScreen("Somethingwentwrong");
              } else if (response.data.result.result.aiserviceURL === undefined) {
                console.log("2", response.data.result.result.aiserviceURL);
                localStorage.setItem("ErrorMessage", "AI Service URL is not set");
                setCurrentScreen("Somethingwentwrong");
              } else if (response.data.result.result.aiserviceURL === null) {
                console.log("3", response.data.result.result.aiserviceURL);
                localStorage.setItem("ErrorMessage", "AI Service URL is not set");
                setCurrentScreen("Somethingwentwrong");
              } else {
                if (localStorage.getItem("isFirstUse") === "false") {
                  setCurrentScreen("ComposeNewMail");
                } else if (localStorage.getItem("isFirstUse") === null) {
                  changeScreen("GetStarted");
                } else {
                  setCurrentScreen("GetStarted");
                }
              }
            } else {
              changeScreen("Expired");
            }
          } else {
            //if user not found
            //console.log("response.data.message:", response.data.message);
            localStorage.setItem("ErrorMessage", response.data.message);
            setCurrentScreen("Somethingwentwrong");
          }

          console.log("Response:", response.data);
          // Process the response data here
        })
        .catch((error) => {
          // Handle any errors
          localStorage.setItem("ErrorMessage", "Something Went Wrong");
          setCurrentScreen("Somethingwentwrong");
          console.error("Error:", error);
        });

      //setCurrentScreen("ComposeMail");
    }
  }, [licenseData]); // The empty dependency array ensures that this effect runs only once on component mount

  const changeScreen = (screen) => {
    if (screen === "FirstScreen") {
      setCurrentScreen("FirstScreen");
    } else if (screen === "QuickSend") {
      setCurrentScreen("QuickSend");
    } else if (screen === "ComposeNewEmail") {
      setCurrentScreen("ComposeNewEmail");
    } else if (screen === "Profile") {
      setCurrentScreen("Profile");
    } else if (screen === "Notifications") {
      setCurrentScreen("Notifications");
    } else if (screen === "Expired") {
      setCurrentScreen("Expired");
    } else if (screen === "ComposeMail") {
      setCurrentScreen("ComposeMail");
    } else if (screen === "ComposeNewMail") {
      setCurrentScreen("ComposeNewMail");
    } else if (screen === "KeyPeoples") {
      setCurrentScreen("KeyPeoples");
    } else {
      setCurrentScreen("GetStarted");
    }
  };

  return (
    <div>
      {currentScreen === "QuickSend" && <QuickSend changeScreen={changeScreen} />}
      {currentScreen === "GetStarted" && <GetStarted changeScreen={changeScreen} />}
      {currentScreen === "Profile" && <Profile changeScreen={changeScreen} />}
      {currentScreen === "Notifications" && <Notifications changeScreen={changeScreen} />}
      {currentScreen === "Expired" && <Expired changeScreen={Expired} />}
      {currentScreen === "Somethingwentwrong" && <Somethingwentwrong changeScreen={Somethingwentwrong} />}
      {currentScreen === "FirstScreen" && <FirstScreen changeScreen={FirstScreen} />}
      {currentScreen === "ComposeMail" && <ComposeMail changeScreen={ComposeMail} />}
      {currentScreen === "ComposeNewMail" && <ComposeNewMail changeScreen={ComposeNewMail} />}
      {currentScreen === "KeyPeoples" && <KeyPeoples changeScreen={KeyPeoples} />}
    </div>
  );
}
